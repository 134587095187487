<template>
    <b-container>
        <div v-show="isUploaderVisible">
            <b-row class="mt-3">
                <b-col cols="auto" class="mr-auto">
                    <span>Select document</span>
                </b-col>
                <b-col cols="auto">
                    <b-button
                        rounded
                        class="buttonSelect nextbutton"
                        :disabled="uploadedFiles.length < 1"
                        @click="next"
                    >
                        Next
                    </b-button>
                </b-col>
            </b-row>
            <b-row class="mt-3">
                <b-col id="droparea" v-cloak>
                    <div class="file-upload-outer-wrapper">
                        <b-icon-cloud-arrow-up
                            class="uploadicon"
                        ></b-icon-cloud-arrow-up>
                        <span>Drag and drop file to upload</span>
                        <span>Or</span>
                        <span>
                            <ejs-uploader
                                ref="uploadObj"
                                id="defaultfileupload"
                                :selected="onSelect"
                                name="UploadFiles"
                                :autoUpload="autoUpload"
                                :dropArea="dropElement"
                                :removing="onFileRemove"
                                :fileListRendering="fileListHandler"
                            ></ejs-uploader>
                        </span>
                    </div>
                </b-col>
            </b-row>
        </div>
        <div v-show="!isUploaderVisible">
            <b-row class="mt-3">
                <b-col cols="auto" class="mr-auto">
                    <span>Document(s)</span>
                </b-col>
                <b-col cols="auto">
                    <b-button
                        rounded
                        class="buttonSelect nextbutton"
                        :disabled="uploadedFiles.length < 1"
                        @click="next"
                    >
                        Back
                    </b-button>
                </b-col>
                <b-col cols="auto">
                    <b-button
                        rounded
                        class="buttonSelect nextbutton"
                        :disabled="uploadedFiles.length < 1"
                        @click="saveFiles"
                    >
                        Upload
                    </b-button>
                </b-col>
            </b-row>
            <b-row class="mt-3">
                <b-col>
                    <div class="accordion" role="tablist">
                        <b-card
                            no-body
                            class="mb-1"
                            v-for="(f, index) in uploadedFiles"
                            :key="index"
                        >
                            <b-card-header
                                header-tag="header"
                                class="p-1"
                                role="tab"
                            >
                                <b-button
                                    block
                                    v-b-toggle="
                                        'imagedetails-accordion' + index
                                    "
                                    variant="info"
                                    ><span class="btn-span-text">{{
                                        f.name
                                    }}</span></b-button
                                >
                            </b-card-header>
                            <b-collapse
                                :id="'imagedetails-accordion' + index"
                                :accordion="'imagedetails-accordion' + index"
                                role="tabpanel"
                            >
                                <b-card-body class="no-padding-margin">
                                    <b-card-text>
                                        <b-container>
                                            <b-row no-gutters="true">
                                                <b-col>
                                                    <span class="details-text"
                                                        >Document details</span
                                                    >
                                                </b-col>
                                            </b-row>
                                            <b-form-group
                                                class="mt-3 mb-0 form-style"
                                            >
                                                <label
                                                    class="input-label"
                                                    for="Document-name"
                                                    >Document name</label
                                                >
                                                <b-form-input
                                                    v-bind:editUserParams="30"
                                                    class="input-style"
                                                    id="Document-name"
                                                    v-model="f.name"
                                                ></b-form-input>
                                            </b-form-group>
                                            <b-form-group
                                                description="Keywords help with
                                                    searching. Separate keywords
                                                    with comma."
                                                class="mt-2 mb-0"
                                            >
                                                <label
                                                    class="input-label"
                                                    for="key-words"
                                                    >Keywords (optional)</label
                                                >
                                                <b-form-input
                                                    class="input-style"
                                                    id="key-words"
                                                    v-model="f.keyword"
                                                ></b-form-input>
                                            </b-form-group>
                                            <b-form-group class="mt-2 mb-0">
                                                <label
                                                    class="input-label"
                                                    for="description"
                                                    >Description
                                                    (optional)</label
                                                >
                                                <textarea
                                                    class="input-style"
                                                    id="description"
                                                    v-model="f.description"
                                                    rows="6"
                                                    max-rows="16"
                                                ></textarea>
                                            </b-form-group>
                                            <b-form-group class="mt-2 mb-0">
                                                <label
                                                    class="input-label"
                                                    for="directory-select"
                                                    >Select directory</label
                                                >
                                                <b-form-select
                                                    class="input-style"
                                                    id="directory-select"
                                                    v-model="f.directory"
                                                    @change="
                                                        getAssignedAttributes(
                                                            f.directory,
                                                            index
                                                        )
                                                    "
                                                >
                                                    <option
                                                        v-for="d in directories"
                                                        :value="d.DirectoryID"
                                                        :key="d.DirectoryID"
                                                    >
                                                        {{ d.dirFullName }}
                                                    </option>
                                                </b-form-select>
                                            </b-form-group>
                                            <div v-if="f.attributes.length > 0">
                                                <b-row no-gutters="true">
                                                    <b-col>
                                                        <span
                                                            class="details-text"
                                                            >Assign
                                                            attributes</span
                                                        >
                                                    </b-col>
                                                </b-row>
                                                <b-row
                                                    v-for="(attribute,
                                                    indexAttr) in f.attributes"
                                                    :key="indexAttr"
                                                >
                                                    <b-col>
                                                        <div
                                                            class="document-upload"
                                                        >
                                                            <div
                                                                v-if="
                                                                    attribute.IsFreeText
                                                                "
                                                                class="attrBox mb-2"
                                                            >
                                                                <span
                                                                    class="details-text"
                                                                >
                                                                    {{
                                                                        attribute.AttributeName
                                                                    }}
                                                                </span>
                                                                <b-form-group
                                                                    class=" mb-0 form-style"
                                                                >
                                                                    <b-form-input
                                                                        v-bind:editUserParams="
                                                                            1
                                                                        "
                                                                        class="input-style"
                                                                        id="Document-name"
                                                                        v-model="
                                                                            attribute
                                                                                .docAttributes
                                                                                .freetext
                                                                        "
                                                                    ></b-form-input>
                                                                </b-form-group>
                                                            </div>
                                                            <div
                                                                v-else
                                                                class="attrBox mb-2"
                                                            >
                                                                <span
                                                                    class="details-text"
                                                                >
                                                                    {{
                                                                        attribute.AttributeName
                                                                    }}
                                                                </span>
                                                                <div
                                                                    v-if="
                                                                        attribute.IsMultiSelect
                                                                    "
                                                                    class="multiBox"
                                                                >
                                                                    <b-form-group
                                                                        class="mb-0"
                                                                        ><b-form-checkbox
                                                                            v-for="(permission,
                                                                            permIndex) in attribute.AttributeValue"
                                                                            :key="
                                                                                permIndex
                                                                            "
                                                                            v-model="
                                                                                permission.ckeckElement
                                                                            "
                                                                            class="mr-3"
                                                                        >
                                                                            {{
                                                                                permission.AttributeValueText
                                                                            }}
                                                                        </b-form-checkbox>
                                                                    </b-form-group>
                                                                </div>
                                                                <div v-else>
                                                                    <!-- prettier-ignore -->
                                                                    <span
                                                                        v-for="(answers,
                                                                        pr) in attribute.AttributeValue"
                                                                        :key="pr"
                                                                    >
                                                                        <input
                                                                            class="singleSelect"
                                                                            type="radio"
                                                                            :value=" answers.AttributeValueID"
                                                                            :checked=" answers.AttributeValueID ==attribute.radioElement"
                                                                            v-modal="answers.AttributeValueID"
                                                                            @change="getSelected(answers.AttributeValueID,index,indexAttr,pr)"
                                                                        />
                                                                        <label
                                                                            class="ml-2"
                                                                            for="one"
                                                                        >
                                                                            {{answers.AttributeValueText}}</label
                                                                        >
                                                                        <br />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </b-col>
                                                </b-row>
                                            </div>
                                        </b-container>
                                    </b-card-text>
                                </b-card-body>
                            </b-collapse>
                        </b-card>
                    </div>
                </b-col>
            </b-row>
        </div>
        <SingleProjectModal
            :isShow="showProjectSelectModal"
            @setSelectedProject="setSelectedProject"
        ></SingleProjectModal>
        <ToastMessages
            :showSuccess="showSuccess"
            :showFailure="showFailure"
            :showConnecting="showConnecting"
            :failureToastMessage="failureToastMessage"
            :connectingToastMessage="connectingToastMessage"
            :successToastMessage="successToastMessage"
        />
    </b-container>
</template>

<script>
import SingleProjectModal from './Modals/SingleProjectModal.vue';
import { mapState } from 'vuex';
import { projectService } from '../services/project.service';
import ToastMessages from './ToastMessages.vue';
export default {
    components: {
        SingleProjectModal,
        ToastMessages,
    },
    data() {
        return {
            uploadedFiles: [],
            isUploaderVisible: true,
            dropElement: '#droparea',
            showProjectSelectModal: false,
            selectedProject: null,
            directories: [],
            autoUpload: false,
            showSuccess: false,
            showFailure: false,
            showConnecting: false,
            failureToastMessage: null,
            connectingToastMessage: null,
            successToastMessage: null,
        };
    },
    methods: {
        setSelectedProject(projID) {
            this.selectedProject = projID;
            this.showProjectSelectModal = false;
        },
        onSelect(args) {
            for (let i = 0; i < args.filesData.length; i++) {
                args.filesData[i]['description'] = '';
                args.filesData[i]['keyword'] = '';
                args.filesData[i]['directory'] = '';
                args.filesData[i]['AttributeName'] = '';
                args.filesData[i]['attributes'] = [];
                this.uploadedFiles.push(args.filesData[i]);
            }
        },
        fileListHandler: function(args) {
            if (args.fileInfo.size < 1000) {
                args.element.getElementsByClassName(
                    'e-file-size'
                )[0].innerText = args.fileInfo.size / 1000 + ' KB';
            }
        },
        onFileRemove(args) {
            args.postRawFile = false;
            this.uploadedFiles.splice(
                this.uploadedFiles.indexOf(args.filesData[0]),
                1
            );
        },
        next() {
            this.isUploaderVisible = !this.isUploaderVisible;
        },
        getDirectories() {
            projectService
                .getListofProjectDirectories(this.selectedProject)
                .then((res) => {
                    this.directories = res.data
                        .map((dir) => {
                            let fName = dir.BlobPath + dir.DirectoryName;
                            let dirfName = fName.replace(
                                this.selectedProject + '/',
                                ''
                            );
                            dir['dirFullName'] = dirfName;
                            return dir;
                        })
                        .sort((a, b) =>
                            a.dirFullName.localeCompare(b.dirFullName)
                        );
                    console.log(this.directories);
                });
        },
        getSelected(value1, indexParent, indexSubParent, index) {
            this.uploadedFiles[indexParent].attributes[
                indexSubParent
            ].radioElement = value1;
            for (let temp in this.uploadedFiles[indexParent].attributes[
                indexSubParent
            ].AttributeValue) {
                if (temp == index) {
                    this.uploadedFiles[indexParent].attributes[
                        indexSubParent
                    ].AttributeValue[temp][
                        'AttributeValueTextSelection'
                    ] = true;
                } else {
                    this.uploadedFiles[indexParent].attributes[
                        indexSubParent
                    ].AttributeValue[temp][
                        'AttributeValueTextSelection'
                    ] = false;
                }
            }
            this.$forceUpdate();
        },
        getAssignedAttributes(directoryId, index) {
            projectService
                .GetDirectoryManageAttribute(directoryId)
                .then((res) => {
                    this.uploadedFiles[index].attributes = res.data;
                    // this.attributes = res.data;
                    this.directoryPresent = false;
                    let vm = this;
                    for (let att in this.uploadedFiles[index].attributes) {
                        this.uploadedFiles[index].attributes[att][
                            'radioElement'
                        ] = false;
                    }
                    for (
                        var i = 0;
                        i < vm.uploadedFiles[index].attributes.length;
                        i++
                    ) {
                        if (
                            vm.uploadedFiles[index].attributes[i].IsMultiSelect
                        ) {
                            for (let chkEle in vm.uploadedFiles[index]
                                .attributes[i].AttributeValue) {
                                vm.uploadedFiles[index].attributes[
                                    i
                                ].AttributeValue[chkEle][
                                    'ckeckElement'
                                ] = false;
                            }
                        }
                        this.uploadedFiles[index].attributes[i][
                            'docAttributes'
                        ] = {
                            freetext: '',
                            check: [],
                            radio: [],
                        };
                        this.uploadedFiles[index].attributes[i]['radio'] =
                            'test' + i;
                    }
                    this.$forceUpdate();
                });
        },
        saveFiles() {
            let uploadTempData = [];
            this.directoryPresent = false;
            const data = new FormData();
            for (let item in this.uploadedFiles) {
                if (this.uploadedFiles[item].directory == '') {
                    this.directoryPresent = true;
                    uploadTempData.push(this.uploadedFiles[item].name);
                    this.$forceUpdate();
                }
                if (!this.directoryPresent) {
                    this.uploadedFiles.forEach((item, index) => {
                        if (item.attributes.length > 0) {
                            data.append(
                                'documentAttributeUploadDTO[' +
                                    index +
                                    '].documents.FileName',
                                item.name
                            );
                            data.append(
                                'documentAttributeUploadDTO[' +
                                    index +
                                    '].documents.description',
                                item.description
                            );
                            data.append(
                                'documentAttributeUploadDTO[' +
                                    index +
                                    '].documents.FormFile',
                                item.rawFile,
                                item.rawFile.name
                            );
                            data.append(
                                'documentAttributeUploadDTO[' +
                                    index +
                                    '].documents.keyword',
                                item.keyword
                            );
                            // data.append("documentAttributeUploadDTO[" + index + "].documents.DocumentAttribute.DocumentAtributeID", item.attributes[0].DirectoryAttributeID);

                            for (let i = 0; i < item.attributes.length; i++) {
                                data.append(
                                    'documentAttributeUploadDTO[' +
                                        index +
                                        '].AttributeTransactInfo[' +
                                        i +
                                        '].DirectoryID',
                                    item.attributes[0].DirectoryID
                                );
                                data.append(
                                    'documentAttributeUploadDTO[' +
                                        index +
                                        '].AttributeTransactInfo[' +
                                        i +
                                        '].ProjectId',
                                    parseInt(this.selectedProject)
                                );
                                data.append(
                                    'documentAttributeUploadDTO[' +
                                        index +
                                        '].AttributeTransactInfo[' +
                                        i +
                                        '].DirectoryAttributeID',
                                    item.attributes[i].DirectoryAttributeID
                                );
                                data.append(
                                    'documentAttributeUploadDTO[' +
                                        index +
                                        '].AttributeTransactInfo[' +
                                        i +
                                        '].AttributeName',
                                    item.attributes[i].AttributeName
                                );

                                data.append(
                                    'documentAttributeUploadDTO[' +
                                        index +
                                        '].AttributeTransactInfo[' +
                                        i +
                                        '].IsFreeText',
                                    item.attributes[i].IsFreeText
                                );
                                data.append(
                                    'documentAttributeUploadDTO[' +
                                        index +
                                        '].AttributeTransactInfo[' +
                                        i +
                                        '].IsMultiSelect',
                                    item.attributes[i].IsMultiSelect
                                );
                                if (item.attributes[i].IsFreeText == true) {
                                    data.append(
                                        'documentAttributeUploadDTO[' +
                                            index +
                                            '].AttributeTransactInfo[' +
                                            i +
                                            '].FreeTextVal',
                                        item.attributes[i].docAttributes
                                            .freetext
                                    );
                                    data.append(
                                        'documentAttributeUploadDTO[' +
                                            index +
                                            '].AttributeTransactInfo[' +
                                            i +
                                            '].AttributeValueTransact',
                                        []
                                    );
                                    data.append(
                                        'documentAttributeUploadDTO[' +
                                            index +
                                            '].DirectoryID',
                                        item.directory
                                    );
                                } else {
                                    for (
                                        let m = 0;
                                        m <
                                        item.attributes[i].AttributeValue
                                            .length;
                                        m++
                                    ) {
                                        data.append(
                                            'documentAttributeUploadDTO[' +
                                                index +
                                                '].AttributeTransactInfo[' +
                                                i +
                                                '].AttributeValueTransact[' +
                                                m +
                                                '].AttributeValueID',
                                            item.attributes[i].AttributeValue[m]
                                                .AttributeValueID
                                        );
                                        data.append(
                                            'documentAttributeUploadDTO[' +
                                                index +
                                                '].AttributeTransactInfo[' +
                                                i +
                                                '].AttributeValueTransact[' +
                                                m +
                                                '].AttributeValueText',
                                            item.attributes[i].AttributeValue[m]
                                                .AttributeValueText
                                        );
                                        data.append(
                                            'documentAttributeUploadDTO[' +
                                                index +
                                                '].AttributeTransactInfo[' +
                                                i +
                                                '].AttributeValueTransact[' +
                                                m +
                                                '].DirectoryAttributeID',
                                            item.attributes[i].AttributeValue[m]
                                                .DirectoryAttributeID
                                        );

                                        if (item.attributes[i].IsMultiSelect) {
                                            data.append(
                                                'documentAttributeUploadDTO[' +
                                                    index +
                                                    '].AttributeTransactInfo[' +
                                                    i +
                                                    '].AttributeValueTransact[' +
                                                    m +
                                                    '].AttributeValueTextSelection',
                                                item.attributes[i]
                                                    .AttributeValue[m]
                                                    .ckeckElement
                                            );
                                        } else {
                                            if (
                                                item.attributes[i]
                                                    .AttributeValue[m]
                                                    .AttributeValueTextSelection ==
                                                undefined
                                            ) {
                                                data.append(
                                                    'documentAttributeUploadDTO[' +
                                                        index +
                                                        '].AttributeTransactInfo[' +
                                                        i +
                                                        '].AttributeValueTransact[' +
                                                        m +
                                                        '].AttributeValueTextSelection',
                                                    false
                                                );
                                            } else {
                                                data.append(
                                                    'documentAttributeUploadDTO[' +
                                                        index +
                                                        '].AttributeTransactInfo[' +
                                                        i +
                                                        '].AttributeValueTransact[' +
                                                        m +
                                                        '].AttributeValueTextSelection',
                                                    item.attributes[i]
                                                        .AttributeValue[m]
                                                        .AttributeValueTextSelection
                                                );
                                            }
                                        }
                                    }
                                    data.append(
                                        'documentAttributeUploadDTO[' +
                                            index +
                                            '].AttributeTransactInfo[' +
                                            i +
                                            '].FreeTextVal',
                                        null
                                    );
                                    data.append(
                                        'documentAttributeUploadDTO[' +
                                            index +
                                            '].DirectoryID',
                                        item.directory
                                    );
                                }
                            }
                        } else {
                            data.append(
                                'documentAttributeUploadDTO[' +
                                    index +
                                    '].documents.FileName',
                                item.name
                            );
                            data.append(
                                'documentAttributeUploadDTO[' +
                                    index +
                                    '].documents.description',
                                item.description
                            );
                            data.append(
                                'documentAttributeUploadDTO[' +
                                    index +
                                    '].documents.FormFile',
                                item.rawFile,
                                item.rawFile.name
                            );
                            data.append(
                                'documentAttributeUploadDTO[' +
                                    index +
                                    '].documents.keyword',
                                item.keyword
                            );
                            data.append(
                                'documentAttributeUploadDTO[' +
                                    index +
                                    '].DirectoryID',
                                item.directory
                            );
                            data.append(
                                'documentAttributeUploadDTO[' +
                                    index +
                                    '].AttributeTransactInfo',
                                []
                            );
                        }
                    });

                    projectService
                        .uploadDocuments(data)
                        .then((res) => {
                            if (res.data.StatusCode === 202) {
                                this.showConnecting = true;
                                var fileNames = null;
                                for (
                                    var i = 0;
                                    i < this.uploadedFiles.length;
                                    i++
                                ) {
                                    fileNames =
                                        fileNames +
                                        ', ' +
                                        this.uploadedFiles[i].name;
                                }
                                if (this.uploadedFiles.length > 1) {
                                    this.connectingToastMessage =
                                        'Uploading files';
                                } else {
                                    this.connectingToastMessage =
                                        'Uploading file';
                                }

                                this.showSuccess = false;
                                // this.showFailure = false;
                                setTimeout(() => {
                                    this.showConnecting = false;
                                    this.showSuccess = true;
                                    if (this.uploadedFiles.length > 1) {
                                        this.successToastMessage =
                                            'Documents uploaded';
                                    } else {
                                        this.successToastMessage =
                                            'Document uploaded';
                                    }
                                    setTimeout(() => {
                                        this.showSuccess = false;
                                    }, 2000);
                                }, 500);
                            }
                        })
                        .catch((res) => {
                            this.failureToastMessage =
                                res.response.data.Message;
                            this.showFailure = true;
                            setTimeout(() => {
                                this.showFailure = false;
                            }, 5000);
                        });
                    this.uploadedFiles = [];
                    this.isUploaderVisible = true;
                } else {
                    this.failureToastMessage =
                        'Please select directory for ' +
                        uploadTempData.join(', ');
                    this.showFailure = true;
                    setTimeout(() => {
                        this.showFailure = false;
                    }, 5000);
                }
            }
        },
    },
    created() {
        if (this.selectedProjectsArr.length > 1) {
            this.showProjectSelectModal = true;
        } else {
            this.selectedProject = this.selectedProjectsArr[0].ProjectID;
        }
    },
    mounted() {
        this.getDirectories();
    },
    computed: {
        ...mapState({
            selectedProjectsArr: function(state) {
                return state.projects.selectedProjects;
            },
        }),
    },
    watch: {
        selectedProject(newVal, oldVal) {
            this.getDirectories();
        },
    },
};
</script>

<style scoped>
.no-padding-margin {
    padding: 0 !important;
    margin: 0 !important;
}
.details-text {
    font-size: 14px;
}
.label-style {
    font-size: 13px;
}
.file-upload-outer-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 195px;
    border: dotted #c6c6c6;
    background-color: #f8f8f8;
}
.uploadicon {
    height: 56px;
    width: 82px;
}
/* To specify height */
.e-upload.e-control-wrapper,
.e-bigger.e-small .e-upload.e-control-wrapper {
    width: 100%;
}
.e-upload-file-list {
    width: 100% !important;
}

.e-upload .e-upload-files {
    width: 100% !important;
}
.buttonSelect {
    background-color: #006395;
    padding: 11px 10px !important;
    border-radius: 3px;
    border: 0px !important;
    min-width: 75px !important;
}
button.btn.btn-info.btn-block.not-collapsed {
    background: #eaeaea url('../assets/minus_sign_big.svg') no-repeat right
        0.75rem center !important;
}
.btn-info {
    color: #000 !important;
    height: 50px;
    text-align: left !important;
    font-size: 20px;
    font-weight: 500;
    border: none !important;
    background: #eaeaea url('../assets/big_plus.svg') no-repeat right 0.75rem
        center !important;
}

.card-header {
    margin-bottom: 20px !important;
    background: #eaeaea !important;
    border: none !important;
    border: 1px solid rgba(0, 0, 0, 0.125) !important;
    height: 59px;
}

@media screen and (min-width: 768px) {
    .input__container .slot-container input {
        padding: 0 !important;
        width: 100% !important;
    }

    .input__container {
        width: 100% !important;
    }
}
.e-file-name {
    max-width: 150px !important;
}
.btn-span-text {
    max-width: 307px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    float: left;
    margin-top: 7px;
}
.input-label {
    font-size: 13px;
    margin-bottom: 0;
}
.input-style {
    display: flex;
    width: 100% !important;
    border-radius: 5px !important;
    border: 1px solid #ced4da;
}
.form-style {
    border-radius: 5px !important;
}
.attrBox {
    border: 1px solid #ccc;
    padding: 10px;
    width: 100%;
    border-radius: 5px;
    background-color: #fbfbfb;
}
.attrBox label {
    font-size: 14px;
}
.multiBox {
    font-size: 14px;
}
</style>
